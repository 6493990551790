/* NotFound.css */
.notfound-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
}

.notfound-title {
  font-size: 10rem;
  margin: 0;
  color: #343a40;
}

.notfound-message {
  font-size: 1.5rem;
  margin: 20px 0;
  color: #6c757d;
}

.notfound-link {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.notfound-link:hover {
  background-color: #0056b3;
}
