/* Add custom styles here if needed */
.card {
  margin: 20px;
  max-width: 90%;
}

.card-img-top {
  max-height: 200px;
  object-fit: cover;
}

.room-card {
  width: 100%;
  position: relative;
}
.not-available {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
